<template>
<CRow>
  <CCol col="12" xl="12">
       <CCard class="cards">
      <CCardHeader>
        <CRow>
        <CCol col="6" class="styleHeader">{{ $t('message.showCustomer') }}  </CCol>
          <CCol col="6">
         <CButton style=" float: left;" color="btn btn-warning"  @click="print">{{$t('message.print')}}  </CButton>
         </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody  id="printMe">
        <div class="row print-row"  >
            <div class="col-md-6">
                <table class="table table-bordered" style="direction:rtl;float:right" >
                  <tr>
                    <th>{{ $t('message.customerName') }}</th>
                    <td>{{ customer.name }}</td>
                  </tr>
                  <tr>
                    <th> {{ $t('message.customerPhone') }}</th>
                    <td>{{ customer.phone }}</td>
                  </tr>
                </table>
            </div>

        </div>
        <br><br><br><hr><hr>
         <div class="row print-row" v-if="transactions.length">
             <CCol col="col-md-6 " style="direction:rtl;float:right" class="styleHeader">{{ $t('message.customerTransaction') }}  </CCol>
            <div class="col-md-10 m-auto">
                <table class="table table-bordered" style="direction:rtl;float:right" >
                  <tr>
                    <th>{{ $t('message.date') }}   </th>
                    <th> {{ $t('message.credit') }}  </th>
                      <th>  {{ $t('message.debit') }}  </th>
                      <th>  {{ $t('message.balance') }}  </th>
                  </tr>
                   <tr v-for=" transaction in transactions" :key="transaction.id">
                    <td>{{ transaction.date }}</td>
                    <td>{{ transaction.paid_from_customer | number}}</td>
                    <td>{{ transaction.needed_from_customer | number}}</td>
                    <td>{{ transaction.balance | number }}</td>
                  </tr>
                </table>
            </div>
         </div>
          <div class="row" v-if="customer.orders">
             <CCol col="6" class="styleHeader" style="direction:rtl;float:right">{{ $t('message.products') }}  </CCol>
             <br><br>
            <div class="col-md-10 m-auto">
                <table class="table table-bordered" style="direction:rtl;float:right">
                  <tr>
                    <th>{{ $t('message.product') }}   </th>
                    <th> {{ $t('message.productPrice') }}  </th>
                      <th>  {{ $t('message.quantity') }}  </th>
                        <th>  {{ $t('message.total') }}  </th>
                      <th>  {{ $t('message.CreatedAt') }}  </th>
                       <!-- <div class="totalpayments">{{totalOrderCustomer }}</div> -->
                  </tr>
                   <tr v-for=" product in products" :key="product.id">
                    <td >{{ product.id }}</td>
                     <td >{{ product.price }}</td>
                     <td >{{ product.quantity }}</td>
                    <td>{{product.total | number}}</td>
                    <td>{{product.created_at }}</td>
                  </tr>
                </table>
            </div>
         </div>
      </CCardBody>
    </CCard>
  </CCol>
</CRow>
</template>
<script>
import moment from 'moment'
import $ from 'jquery'

export default {
  name: 'PrintCustomer',
  data () {
    return {
      customer: [],
      totalOrderCustomer: {},
      totalInvoicesCustomer: {},
      balance: '',
      transactions: [],
      products: [],
      moment: moment
    }
  },
  created () {
    console.log(this.$route.params.id)
    this.$http
      .get(`${this.$hostUrl}customers/${this.$route.params.id}`)
      .then((response) => {
        this.customer = response.data.data.customer
        this.totalOrderCustomer = response.data.data.totalOrderCustomer
        this.totalInvoicesCustomer = response.data.data.totalInvoicesCustomer
        this.balance = response.data.data.balance
        this.transactions = response.data.data.transactions
        const products = []
        $.each(this.customer.orders, (key, val) => {
          $.each(val.products, (key2, val2) => {
            products.push({
              id: val2.id,
              price: val2.price,
              quantity: val2.quantity,
              total: val2.total,
              created_at: val2.created_at
            })
          })
        })
        this.products = products
        console.log(Array.from(this.products))
      })
  },
  methods: {
    orderDetail (id) {
      this.$router.push({ path: '/orders/' + id + '/show' })
    },
    paymentDetail (id) {
      this.$router.push({ path: '/payments/' + id + '/show' })
    },
    print () {
      this.$htmlToPaper('printMe',
       `@media print {
            ::v-deep .right-sidebar,
      ::v-deep header {
      display: block !important;
      background:red !important;
      }
      ::v-deep footer {
     display: none !important;
     }
                }`)
    },
    goBack () {
      this.citiesOpened ? this.$router.go(-1) : this.$router.push({ path: '/customers' })
    }
  }
}
</script>
<style scoped>
  th {
    background-color: #643c4f;
    color: #f5f3f3;
  }
  .العربية .print-row, .العربية .print-row .col-md-6, .العربية .print-row .tabel{

  direction: rtl !important;
 }
.العربية .print-row .col-md-6, .العربية .print-row .table{
    float: right !important;
}
.totalpayments{
      position: absolute;
    top: 66px;
    left: 78px;
}
#details{
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}
</style>
